<template>
    <div class="text-white bg-transparent w-full text-center mx-auto lg:py-6 py-2 lg:text-5xl text-lg" v-html="titolo"></div>
</template>

<script>
export default {
  name: "Title",
  props: ['titolo'],
  mounted() {
      console.log("TITOLO");
  }
};
</script>