<template>
<span class="relative">
  <transition name="bounce">
    <section v-show="show" class="bg-white relative my-8 rounded-xl shadow-xl mx-6" >
      <div class="w-full flex flex-col lg:flex-row justify-between p-6 px-2 md:px-6 lg:px-16 lg:py-16 border-t-0 border-grey-500 relative h-full">
        
        <div class="lg:order-2 order-4 flex flex-col justify-center h-38 lg:w-3/5 lg:card-height p-6 lg:mr-8">
          <div href="#header" class="text-black text-2xl lg:text-4xl text-flip font-bold leading-none" v-html="header" ></div>
          <div class="text-black text-xl text-flip font-normal mt-4 lg:mt-6 leading-7" v-html="body" ></div>
        </div>

        <div class="flex flex-col justify-center lg:card-height lg:w-2/5 relative overflow-hidden" :class="img_order">
          <img :src="require('../assets/' + img)" class="img-size object-fit" />
        </div>

      </div>
    </section>
  </transition>
    <section v-show="!show" class="opacity-0 bg-white relative my-8 rounded-xl shadow-xl mx-6" >
      <div class="w-full flex flex-col lg:flex-row justify-between p-6 px-2 md:px-6 lg:px-16 lg:py-16 border-t-0 border-grey-500 relative h-full">
        
        <div class="lg:order-2 flex flex-col justify-center h-38 lg:w-3/5 lg:card-height p-6 lg:mr-8">
          <div href="#header" class="text-black text-2xl lg:text-4xl text-flip font-bold leading-none" v-html="header" ></div>
          <div class="text-black text-xl text-flip font-normal mt-4 lg:mt-6 leading-7" v-html="body" ></div>
        </div>

        <div class="flex flex-col justify-center lg:card-height lg:w-2/5 relative overflow-hidden" :class="img_order">
          <img :src="require('../assets/' + img)" class="img-size object-fit" />
        </div>

      </div>
    </section>
</span>
</template>

<script>
export default {
  name: "Card",
  props: {
    header: String,
    body: String,
    img: String,
    flip: Boolean,
    show: Boolean
  },
  computed: {
    image() {
      return "./assets/" + this.img;
    },
    img_order() {
        if (this.flip)
            return 'order-1'
        else
            return 'order-3'
    }
  },
};
</script>

<style scoped>
    .card-height {
        height: 36rem;
    }
    .img-size {
      width: auto;
      height: auto;
    }

    .bounce-enter-active {
      animation: bounce-in .8s;
    }
    .bounce-leave-active {
      animation: bounce-in .8s reverse;
    }
    @keyframes bounce-in {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
</style>