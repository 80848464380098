<template>
  <div id="app" class="overflow-y-scroll h-screen w-screen">
    <DynamicBackground :lastscrollposition="lastScrollPosition" />
    <TopBar class="z-40" />
    <span id="ind" class="" />
    <SubTopBar
      class="z-40"
      id="industria"
      :scroll="lastScrollPosition"
      title="Industria 4.0"
      bg="bg-gray-100 text-black"
    />
    <section class="relative bg-gray-100 w-full -mt-8 py-16 bg-gray-100">
      <section
        v-for="card in industria"
        :key="card.id"
        class="transition-all duration-500 relative max-w-screen-xl mx-auto"
      >
        <Card
          :header="card.header"
          :body="card.body"
          :img="card.img"
          :flip="card.flip"
          :show="checkCard(card)"
        />
      </section>
    </section>
    <div class="hidden relative h-screen bg-gray-600 w-screen" />
    <span id="int" class="" />
    <SubTopBar
      class="z-40"
      id="integrazione"
      :scroll="lastScrollPosition"
      title="Integrazione"
      bg="bg-gray-600 text-white"
    />

    <div
      class="
        relative
        bg-gray-900
        w-full
        lg:p-16
        p-2
        flex flex-col
        justify-center
      "
    >
      <Title titolo="Come funziona l'integrazione?" />
      <div class="relative py-6">
        <img
          class="relative lg:max-w-screen-xl md:max-w-screen-md h-auto mx-auto"
          :src="require('./assets/TRACKER.png')"
        />
      </div>
    </div>

    <section class="relative bg-gray-500 w-full py-16">
      <section
        v-for="card in integrazione"
        :key="card.id"
        class="transition-all duration-500 relative max-w-screen-xl mx-auto"
      >
        <Card
          :header="card.header"
          :body="card.body"
          :img="card.img"
          :flip="card.flip"
          :show="checkCard(card)"
        />
      </section>
    </section>
    <!--<span id="clo" class="" />
    <SubTopBar
      class="z-40"
      id="cloud"
      :scroll="lastScrollPosition"
      title="Gestione in Cloud"
      bg="bg-gray-900 text-white"
    />
    <section-- class="relative bg-gray-800 w-full py-16 bg-gray-100">
      <section
        v-for="card in cloud"
        :key="card.id"
        class="transition-all duration-500 relative max-w-screen-xl mx-auto"
      >
        <Card
          :header="card.header"
          :body="card.body"
          :img="card.img"
          :flip="card.flip"
          :show="checkCard(card)"
        />
      </section>
    </section-->
    <span id="car" />
    <SubTopBar
      class="z-40"
      id="carraro"
      :scroll="lastScrollPosition"
      title="OnCharge per Carraro"
      bg="bg-gray-200 text-black"
    />
    <section class="relative w-full -mt-8 bg-gray-100">
      <section
        v-for="card in carraro"
        :key="card.id"
        class="transition-all duration-500 relative w-screen"
      >
        <FullCard
          :header="card.header"
          :body="card.body"
          :theme="card.theme"
          :flip="card.flip"
          :src="card.src"
          :data="card.data"
          :show="checkCard(card)"
        />
      </section>
    </section>
    <div class="fixed z-50 bottom-0 left-0 w-full bg-purple-400 text-xs text-white font-semibold text-center py-1">developed by OnCharge <span class="text-purple-800">Research & Development</span></div>
  </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";
import Card from "./components/Card.vue";
import SubTopBar from "./components/SubTopBar.vue";
import TopBar from "./components/TopBar.vue";
import Title from "./components/Title.vue";
import DynamicBackground from "./components/DynamicBackground.vue";
import FullCard from "./components/FullCard.vue";

export default {
  name: "App",
  data() {
    return {
      lastScrollPosition: 0,
      animation: true,
      industria: [
        // Industria 4.0
        {
          id: 0.4,
          header: "La tua azienda sotto controllo tramite software gestionale",
          body: "Gestionale per il controllo completo delle <b>statistiche, telemetrie e gestione remota dei mezzi</b>, con la possibilità di impostare modelli ad hoc per <b>prevedere e prevenire</b> malfunzionamenti e guasti tecnici.",
          img: "gestionale.png",
          flip: false,
          show: 90,
        },
        {
          id: 1,
          header: "Automatizzazione dei processi verticali",
          body: "Comunicazione in <b>real time</b> tra le macchine, l’app e il software gestionale, atta ad ottimizzare i tempi di comunicazione interni all’azienda.<br><br><b>Comunicazione bidirezionale</b> tra macchina, sistema gestionale e interfaccia operatore.",
          img: "processi-verticali.png",
          flip: true,
          show: 490,
        },
        {
          id: 2,
          header: "Automatizzazione dei processi orizzontali",
          body: "Esposizione di <b>un sistema di API</b>, studiato per l’inserimento rapido di nuovi player nella propria supply-chain o per permettere al sistema di integrarsi in quella di terzi.",
          img: "processi-orizzontali.png",
          flip: false,
          show: 890,
        },
        {
          id: 3,
          header: "Dati in cloud",
          body: "Salvataggio dei dati su <b>piattaforme di Cloud-Computing dedicate</b> e accessibili dai player della supply-chain",
          img: "cloud-data.png",
          flip: true,
          show: 1290,
        },
      ],
      integrazione: [
        // Integrazione
        {
          id: 6,
          header: "Controllo per mezzo CNC",
          body: "Installazione dispositivo per interfaccia tramite CAN BUS per il <b>controllo e diagnostica del mezzo, salvataggio delle telemetrie per analisi, elaborazione dati</b> nonché per il controllo dello <b>stile di guida dell'operatore</b> con la possibilità di impostare modelli ad hoc per <b>prevedere e prevenire</b> malfunzionamenti e guasti tecnici.",
          img: "tracker-gps.png",
          flip: true,
          show: 90,
        },
        {
          id: 7,
          header: "Integrazione con i sistemi preesistenti",
          body: "<b>Interconnessione ai sistemi informatici di fabbrica</b> e <b>integrazione automatizzata</b> al sistema logistico di fabbrica tramite API per garantire una <b>comunicazione bidirezionale</b> tra i mezzi e la fabbrica nonchè con l'utilizzatore finale.",
          img: "integrazione.png",
          flip: false,
          show: 490,
        },
        {
          id: 8,
          header: "Attenzione ai più recenti parametri di sicurezza e salute",
          body: "<b>Monitoraggio telemetria</b> con <b>confronto su modelli personalizzati ad hoc</b> per garantire i requisiti di <b>sicurezza</b> richiesti sul lavoro, ad esempio controllo su temperatura cabina, inclinazione eccessiva <b>antiribaltamento</b>, possibilità di segnalazione di <b>SOS</b> da parte dell’operatore sia tramite <b>app</b> che tramite <b>pulsante fisico</b>.",
          img: "safety.png",
          flip: true,
          show: 890,
        },
      ],
      cloud: [
        // Gestione in Cloud
        {
          id: 9,
          header: "Sviluppo software gestionale ad hoc",
          body: '<ol class="pl-6 list-disc">\
            <li class="mt-2">Sezione ‘anagrafica clienti\' </li>\
            <li class="mt-2">Sezione ‘anagrafica mezzi’</li>\
            <li class="mt-2">Sezione ‘limiti mezzi’, con particolare attenzione alle scadenze tagliandi e ai parametri di usura e manutenzione dei componenti</li>\
            <li class="mt-2">Sezione ‘tracciamento flotta’ per avere un controllo in tempo reale della posizione dei mezzi</li>\
            <li class="mt-2">Sezione ‘alert flotta’, dove verranno visualizzati tutti i mezzi che hanno superato i relativi limiti impostati nella sezione precedente e, qualora si trattasse di un mezzo appartenente ad un cliente, i contatti del proprietario del mezzo</li>\
            <li class="mt-2">Sezione ‘notifiche’ da cui si potranno far partire notifiche personalizzate per fasce di clienti, ottenute dall’analisi dei dati statistici forniti dal mezzo e dall’app</li>\
            <li class="mt-2">Sezione e-commerce (composta da prodotti, gestione ordini e magazzino) per la rivendita dei mezzi e dei ricambi</li>\
          </ol>',
          img: "graph.png",
          flip: true,
          show: 90,
        },
        {
          id: 10,
          header: "Giornale di campagna",
          body: '<ol class="pl-6 list-disc">\
            <li class="mt-2">Sezione ‘anagrafica clienti\' </li>\
            <li class="mt-2">Sezione ‘anagrafica mezzi’</li>\
            <li class="mt-2">Sezione ‘limiti mezzi’, con particolare attenzione alle scadenze tagliandi e ai parametri di usura e manutenzione dei componenti</li>\
            <li class="mt-2">Sezione ‘tracciamento flotta’ per avere un controllo in tempo reale della posizione dei mezzi</li>\
            <li class="mt-2">Sezione ‘alert flotta’, dove verranno visualizzati tutti i mezzi che hanno superato i relativi limiti impostati nella sezione precedente e, qualora si trattasse di un mezzo appartenente ad un cliente, i contatti del proprietario del mezzo</li>\
            <li class="mt-2">Sezione ‘notifiche’ da cui si potranno far partire notifiche personalizzate per fasce di clienti, ottenute dall’analisi dei dati statistici forniti dal mezzo e dall’app</li>\
            <li class="mt-2">Sezione e-commerce (composta da prodotti, gestione ordini e magazzino) per la rivendita dei mezzi e dei ricambi</li>\
          </ol>',
          img: "tracking.png",
          flip: true,
          show: 90,
        },
      ],
      carraro: [
        // Gestione in Cloud
        {
          id: 10,
          header:
            "Installazione dispositivo per interfaccia tramite CAN BUS per il controllo e diagnostica del mezzo, nonché per il controllo dello stile di guida del driver*",
          body: '<br>\
            <ol class="font-semibold text-purple-600 text-2xl text-left">\
            <li class="">Velocità</li>\
            <li class="mt-3">Consumi</li>\
            <li class="mt-3">Emissioni</li>\
            <li class="mt-3">Temperatura olio</li>\
            <li class="mt-3">Temperatura carburante</li>\
            <li class="mt-3">Stato cruise control</li>\
            <li class="mt-3">Corrente alternatore</li>\
            <li class="mt-3">Tensione batterie</li>\
            <li class="mt-3">Motore acceso / spento</li>\
          </ol><br>\
          <span class="text-sm text-left w-full">*Una lista completa delle funzioni disponibili (salvo mancata compatibilità con il mezzo del cliente) è consultabile nell’apposita tabella</span>',
          theme: "light",
          flip: false,
          show: 90,
        },
        {
          id: 10.5,
          show: 490,
          theme: "light",
          src: "trattore-cover.jpeg",
          data: {
            slides: [
              {
                id: 1,
                image:
                  "https://picsum.photos/id/1006/992/850",
                title: "",
                content: "",
              },
              {
                id: 2,
                image:
                  "https://picsum.photos/id/1006/992/850",
                title: "",
                content: "",
              },
            ],
            autoplay: false,
            fade: false,
            lazy: false,
            arrows: false,
            parallax: 1,
          },
        },
        {
          id: 11,
          header: "Sviluppo software gestionale ad hoc",
          body: '<br>\
          <ol class="pr-6">\
            <li class="mt-4">Sezione anagrafica rivenditori, aziende agricole, clienti, operatori.</li>\
            <li class="mt-4">Sezione <b>anagrafica mezzi</b>.</li>\
            <li class="mt-4">Sezione limiti mezzi, con particolare attenzione alle <b>scadenze tagliandi</b> e ai parametri di usura e <b>manutenzione dei componenti</b>.</li>\
            <li class="mt-4">Sezione <b>tracciamento flotta</b> per avere un controllo in tempo reale della posizione dei mezzi</li>\
            <li class="mt-4">Sezione <b>alert</b> flotta, dove verranno visualizzati tutti i mezzi che hanno superato i relativi limiti di usura, con possibilità di notifica al proprietario.</li>\
            <li class="mt-4">Sezione notifiche da cui si potranno far partire notifiche push personalizzate.</li>\
            <li class="mt-4">Sezione e-commerce (inserimento e vetrina prodotti, gestione ordini e magazzino) per la rivendita dei mezzi e dei ricambi.</li>\
            <li class="mt-4">Supporto multilingua</li>\
          </ol>',
          theme: "dark",
          flip: false,
          show: 890,
        },
        {
          id: 11.5,
          show: 1290,
          theme: "light",
          src: "mokap-cellphone-sdraiati.png",
          data: {
            slides: [
              {
                id: 1,
                image:
                  "https://picsum.photos/id/1006/992/850",
                title: "",
                content: "",
              },
              {
                id: 2,
                image:
                  "https://picsum.photos/id/1006/992/850",
                title: "",
                content: "",
              },
            ],
            autoplay: false,
            fade: false,
            lazy: false,
            arrows: false,
            parallax: 1,
          },
        },
        {
          id: 12,
          header:
            "Sviluppo App dedicata per gestore della flotta (contenente le stesse funzionalità del gestionale) e utente finale",
          body: '<br>\
          <ol class="pr-6">\
            <li class="mt-4">Sezione <b>profilo personale</b> contenente l’anagrafica del cliente, un sistema di valutazione dello stile di guida a punti, le statistiche di utilizzo del mezzo (velocità media, km percorsi, ecc…) e storico delle telemetrie, oltre a eventuali suggerimenti per migliorare il proprio <b>stile di guida</b> al fine di <b>risparmiare carburante</b> e <b>ridurre l’usura dei componenti</b>.</li>\
            <li class="mt-4">Mappa per controllare in tempo reale la posizione del proprio mezzo.</li>\
            <li class="mt-4">Sezione <b>stato mezzo</b> dalla quale sarà possibile controllare in tempo reale lo <b>stato dei componenti</b> e del <b>livello dei liquidi del mezzo</b>, al fine di poter intervenire tempestivamente nella manutenzione.</li>\
            <li class="mt-4">Implementazione di <b>notifiche intelligenti</b>, che avvisano il cliente dell’imminente sostituzione di un componente, con relativo link al prodotto sull’e-commerce proprietario.</li>\
            <li class="mt-4">Supporto multilingua.</li>\
          </ol>',
          theme: "light",
          flip: false,
          show: 1690,
        },
        {
          id: 12.3,
          header:
            "Gestione Task e Giornale di campagna",
          body: '<br>\
          <ol class="pr-6">\
            <li class="mt-4">Creazione da gestionale di <b>aree di lavoro</b> etichettabili con funzione di <b>tracciamento e geofencing</b>.</li>\
            <li class="mt-4">Creazione di task di lavoro singole o cicliche e assegnazione di macchine, aree e operatori.</li>\
            <li class="mt-4">Assegnazione automatica alla task degli accessori di lavoro (tramite riconoscimento con sensori bluetooth).</li>\
            <li class="mt-4"><b>Tracciamento dell\'attività</b> con distinzione tra fase di spostamento su strada e lavoro sul campo con <b>gestione intelligente dei tracciati</b>.</li>\
            <li class="mt-4">Comunicazione <b>bidirezionale</b> tra <b>smartphone/smartwatch</b> dell\'operatore e gestionale backend.</li>\
            <li class="mt-4">Segnalazioni di <b>condizioni critiche</b> automatiche e manuali, possibilità di segnalare guasti e anomalie.</li>\
            <li class="mt-4">Supporto multilingua.</li>\
          </ol>',
          theme: "dark",
          flip: false,
          show: 1690,
        },
        {
          id: 13,
          header: "Sistema e-commerce",
          body: '<br>\
          <ol class="pr-6">\
            <li class="mt-4">Riconoscimento del cliente e importazione automatica del profilo</li>\
            <li class="mt-4">Negozio elettronico</li>\
            <li class="mt-4">Carrello personalizzato</li>\
          </ol>',
          theme: "light",
          flip: false,
          show: 2490,
        },
        {
          id: 15.5,
          show: 2090,
          theme: "light",
          src: "mokup-pc.png",
          data: {
            slides: [
              {
                id: 1,
                image:
                  "https://picsum.photos/id/1006/992/850",
                title: "",
                content: "",
              },
              {
                id: 2,
                image:
                  "https://picsum.photos/id/1006/992/850",
                title: "",
                content: "",
              },
            ],
            autoplay: false,
            fade: false,
            lazy: false,
            arrows: false,
            parallax: 1,
          },
        },
        {
          id: 14,
          header:
            "Implementazione API ad hoc per l’interfaccia con il sistema aziendale preesistente",
          body: "",
          theme: "light",
          flip: false,
          show: 2890,
        },
        {
          id: 15,
          header:
            "Redazione di una relazione tecnica e sviluppo di un protocollo Basato sugli standard più diffusi e personalizzato ad hoc per l’interfaccia con altre aziende della supply-chain, in rispetto delle specifiche dell'industria 4.0",
          body: "",
          theme: "dark",
          flip: false,
          show: 3290,
        },
      ],
    };
  },
  computed: {
    animation_header() {
      if (this.animation) return "animate-pulse";
      return "";
    },
  },
  mounted() {
    let self = this;
    setTimeout(function () {
      self.animation = false;
    }, 2000);

    document.getElementById("app").addEventListener("scroll", function () {
      let s = document.getElementById("app").scrollTop;
      //console.log(s);
      self.lastScrollPosition = s;
    });
  },
  methods: {
    checkCard(card) {
      return this.lastScrollPosition - card.id * (window.innerHeight / 2.4) > 0;
    },
  },
  components: {
    Card,
    TopBar,
    Title,
    SubTopBar,
    DynamicBackground,
    FullCard,
  },
};
</script>

<style>
:root {
  --tab-bar-height: 5rem;
}
body::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: white; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid white; /* creates padding around scroll thumb */
}
.tab-bar-height {
  height: var(--tab-bar-height);
}
.section-height {
  height: calc(100vh - var(--tab-bar-height) - 1rem);
}
</style>
