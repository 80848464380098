<template>
<section id="bar" class="sticky bg-black w-full top-0 left-0 flex flex-col tab-bar-height">
    <div class="flex flex-row flex-wrap justify-center bg-white bg-opacity-100 shadow-xl w-full h-full">
        <a @click="goTo('ind')" class="text-black font-bold text-xs md:text-base lg:text-xl px-2 md:px-4 lg:px-6 cursor-pointer h-full flex flex-col justify-center" >Industria 4.0</a>
        <a @click="goTo('int')" class="text-black font-bold text-xs md:text-base lg:text-xl px-2 md:px-4 lg:px-6 cursor-pointer h-full flex flex-col justify-center" >Integrazione</a>
        <!--a @click="goTo('clo')" class="text-black font-bold text-xs md:text-base lg:text-xl px-2 md:px-4 lg:px-6 cursor-pointer h-full flex flex-col justify-center" >Piattaforma</a-->
        <a @click="goTo('car')" class="text-black font-bold text-xs md:text-base lg:text-xl px-2 md:px-4 lg:px-6 cursor-pointer h-full flex flex-col justify-center" >OnCharge per Antonio Carraro</a>
    </div>
</section>
</template>

<script>
    export default {
    name: "TopBar",
    data(){
        return {
            lastScrollPosition: 0
        }
    },
    methods: {
        goTo(id) {
            let el = document.getElementById(id);
            el.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth"
            });
        }
    },
    mounted() {
        var self = this;
        document.getElementById("bar").addEventListener("position", function () {
            console.log("TOPBAR", self.lastScrollPosition);
        });
    },
    };
</script>