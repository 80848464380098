<template>
  <section class="sticky top-0 left-0 p-0 m-0 w-screen">
    <div
      class="
        relative
        bg-black
        section-height
        w-auto
        flex flex-col
        lg:flex-row
        pt-32
        lg:pt-0
        justify-evenly
      "
    >
      <div
        class="
          absolute
          left-0
          top-8
          px-8
          text-white text-base
          lg:text-2xl
          text-center
          flex flex-row
          justify-center
          md:justify-start
          lg:justify-start
          w-full
          h-12
        "
      >
        <span
          class="
            order-2
            pl-2
            flex flex-col
            text-left
            leading-none
            h-full
            justify-end
            text-xs
          "
        >
          <span class="text-white">Developed by OnCharge</span>
          <span class="text-purple-500">Research & Development</span>
        </span>
        <span class="order-1 flex flex-row justify-start h-full">
          <img class="w-auto h-12" :src="require('../assets/logo.png')" />
        </span>
      </div>
      <div class="text-5xl text-white p-8 hidden">
        {{ lastscrollposition }} {{ styleImgLeft }} {{ styleImgRight }}
      </div>
      <div
        class="
          pt-8
          lg:pt-0 lg:absolute
          bottom-0
          lg:pl-32
          flex flex-col
          h-1/2
          lg:h-full
          justify-center
          text-center
        "
        :style="styleImgLeft"
      >
        <img
          class="h-5/6 mx-auto"
          :src="require('../assets/carraro-logo.png')"
        />
      </div>
      <div
        class="
          lg:absolute
          bottom-0
          lg:pr-16
          flex flex-col
          h-1/2
          w-auto
          lg:h-full
          justify-end
        "
        :style="styleImgRight"
      >
        <img class="h-5/6 mx-auto" :src="require('../assets/hand-mokap.png')" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DynamicBackground",
  data() {
    return {
      px_limit: 800,
    };
  },
  props: ["lastscrollposition"],
  mounted() {
    this.px_limit = window.innerWidth / 3;
  },
  computed: {
    styleImgLeft() {
      let scroll = this.lastscrollposition;
      if (scroll > this.px_limit) scroll = this.px_limit;
      return "left: " + scroll + "px;";
    },
    styleImgRight() {
      let scroll = this.lastscrollposition;
      if (scroll > this.px_limit) scroll = this.px_limit;
      return "right: " + scroll + "px;";
    },
  },
};
</script>