<template>
<section :id="id" class="sticky bg-black w-full left-0" style="top: var(--tab-bar-height);">
    <div class="transition-all duration-300 flex flex-row flex-wrap justify-center bg-opacity-100 shadow-xl w-full" :class="[bg, opacity]">
        <a class="font-bold text-xs md:text-base lg:text-xl px-2 md:px-4 lg:px-6 cursor-pointer h-full flex flex-col justify-center" v-html="title"></a>
    </div>
</section>
</template>

<script>
    export default {
    name: "SubTopBar",
    props: ['bg', 'title', 'scroll', 'id'],
    data(){
        return {
            opacity: 'opacity-0 h-0 py-0',
        }
    },
    watch: {
        scroll: function (newVal) {
            let top = newVal;
            top = document.getElementById(this.id).getBoundingClientRect().top;
            //console.log("ID", this.id, "TOP:", top, "; NEWVAL:", newVal);
            if (top > document.getElementById('bar').clientHeight) this.opacity = 'opacity-0 h-0 py-0';
            else this.opacity = "opacity-100 py-6";
        }
    },
    mounted() {
    },
    };
</script>