<template>
    <span class="relative" :class="getTheme">
        <section class="relative">
            <div v-if="data" class="">
                <img class="h-96 w-full object-cover" :src="require('../assets/' + src)">
              <!--
              <Parallax :data="data" style="z-index:1000!important"/>
                
                -->
            </div>
            <div v-else class="w-full flex flex-row justify-center IBM text-2xl lg:flex-row justify-between p-4 px-2 md:px-6 lg:px-8 lg:py-8 border-t-0 relative h-full text-center" :class="getTheme">          
                <div class="lg:order-2 flex flex-col text-left justify-center h-38 lg:card-height p-6 mx-auto max-w-screen-2xl w-full">
                    <div href="#header" class="text-3xl lg:text-4xl text-left text-flip font-bold leading-none max-w-screen-2xl" v-html="header"></div>
                    <div class="text-2xl text-flip font-normal mt-4 lg:mt-6 leading-7" v-html="body" :class="{'lg:pr-36 lg:text-left':!flip, 'lg:pl-36 lg:text-right':flip}"></div>
                </div>
            </div>
        </section>
    </span>
</template>

<script>

//import Parallax from "./Parallax.vue";

export default {
  name: "FullCard",
  props: {
    header: String,
    body: String,
    flip: Boolean,
    src: {
      type: String,
      default: '',
      required: false,
    },
    data: {
      type: Object,
      default: () => {},
      required: false
    },
    theme: {
      type: String,
      default: "light",
    },
  },
  computed: {
    img_order() {
      if (this.flip) return "order-1";
      else return "order-3";
    },
    getTheme() {
      switch (this.theme) {
        case "dark":
          return "bg-gray-800 text-white";
        case "transparent":
          return "bg-transparent text-white";
        case "semidark":
          return "bg-gray-500 text-white";
        default:
          return "bg-gray-100 text-black";
      }
    },
  },
  components: {
    //Parallax
  },
};
</script>

<style scoped>
.card-height {
  height: 36rem;
}

.bounce-enter-active {
  animation: bounce-in 0.8s;
}
.bounce-leave-active {
  animation: bounce-in 0.8s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.IBM {
  font-family: "IBM";
  line-height: 2rem !important;
}
</style>